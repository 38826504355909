nav#mobile {
  background-color: $master-blue;
  padding: $space-s $space-m;
  position: relative;
  #nav-toggle {
    width: 37px;
    height: 27px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 20%;
      width: 100%;
      background: $white;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 40%;
      }
      &:nth-child(3) {
        top: 40%;
      }
      &:nth-child(4) {
        top: 80%;
      }
    }
    &.open span {
      &:nth-child(1) {
        top: 20%;
        width: 0;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0;
        left: 50%;
      }
    }
  }
  .nav-menu{
    list-style: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    flex-direction: column;
    animation-duration: .25s;
    li{
      padding: $space-s;
      margin: 0;
      display: flex;
      align-items: center;
      position: relative;
      text-transform: capitalize;
      a {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 26px;
        line-height: 26px;
        width: 90%;
        overflow: hidden;
      }
      &.has-sub::after {
        position: absolute;
        content: '›';
        right: $space-s;
        top: 7px;
        font-size: 34px;
        font-family: Arial, Helvetica;
        font-weight: 100;
      }
    }
    &.active {
      display: flex;
    }
  }

  .nav-mobile--container{
    background-color: $master-blue-overlay;
    z-index: 2;
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 45px;
    left: 0;
    overflow-x: hidden;
    color: $white;
    transition: all .25s ease-in-out;
    transform: translateX(-100%);
    &.open{
      transform: translateX(0);
      transition: all .25s ease-in-out;
    }
  }
}
