
//colors
$white: #fff;
$color_mine_shaft_approx: #333;
$color_fun_blue_approx: #1f5ba0;
$color_rum_approx: #6a668a;
$color_meteor_approx: #c47610;
$color_athens_gray_approx: #edf1f5;
$color_san_juan_approx: #254c76;
$color_lemon_chiffon_approx: #fffccc;
$color_peppermint_approx: #e1f4e2;
$color_bilbao_approx: #1f7023;
$color_highland_approx: #649966;
$color_beeswax_approx: #fcf2c6;
$color_mystic_approx: #dee7f1;
$color_pigeon_post_approx: #b4c6da;
$color_storm_dust_approx: #666;
$color_fire_bush_approx: #f09d31;
$color_chamois_approx: #f2d5b0;
$color_mountain_mist_approx: #999;
$color_geyser_approx: #d2e0eb;
$color_tapa_approx: #777;
$color_ironside_gray_approx: #676767;
$color_wheatfield_approx: #f3e9ca;
$color_tussock_approx: #d3984c;
$color_gold_sand_approx: #e2bb88;
$color_almond_approx: #f0ddc3;
$color_steel_blue_approx: #5482b6;
$color_old_rose_approx: #cb7f7f;
$color_vanilla_ice_approx: #f8dbdb;
$color_santas_gray_approx: #95a3b3;
$color_ghost_approx: #c9d0d8;
$color_bermuda_gray_approx: #6e86a0;
$color_blue_bayoux_approx: #465f7b;
$color_loblolly_approx: #bec9d5;
$color_red_berry_approx: #900;
$color_chelsea_cucumber_approx: #77aa67;
$black: #000;
$color_rum_swizzle_approx: #f8f7e4;
$color_spring_wood_approx: #f7f7ef;
$color_fun_blue_50_approx: rgba(31,91,160,.5);
$color_silver_chalice_approx: #aaa;

//urls
$url_0: url(../img/bg-b.gif);
$url_1: url(../img/bg-t-new.png);
$url_2: url(../img/s-bg.png);
$url_3: url(../img/subnav.png);
$url_4: url(../img/nav.gif);
$url_5: url(../img/photocredit-bg.png);
$url_6: url(../img/tagline.png);
$url_7: url(../img/tagline-home.png);
$url_8: url(../img/number1.png);
$url_9: url(../img/h1-home.gif);
$url_10: url(../img/li-burst-blue.gif);
$url_11: url(../img/li-dot.gif);
$url_12: url(../img/li-action.gif);
$url_13: url(../img/ic-error.gif);
$url_14: url(../img/rdbluebox-b.gif);
$url_15: url(../img/rdbluebox-t.gif);
$url_16: url(../img/li-burst-orange.gif);
$url_17: url(../img/sep-wave.png);
$url_18: url(../img/quote-tlbg.gif);
$url_19: url(../img/quote-brbg.gif);
$url_20: url(../img/route-bg.jpg);
$url_21: url(../img/sep-wave.gif);
$url_22: url(../img/icon-listen.gif);
$url_23: url(../img/icon-listenb.gif);
$url_24: url(../img/bg-aslowas.png);
$url_25: url(../img/li-arrow.gif);
$url_26: url(../img/1x1-trans.gif);
$url_27: url(../img/ss/arrow-prev.png);
$url_28: url(../img/ss/arrow-next.png);
$url_29: url(../img/ss/pagination.png);

//@extend-elements
//original selectors
//ul, li
%no-bullet {
  list-style: none;
  margin: 0;
  padding: 0;
}

//original selectors
//#reservesteps, #reservesteps li
%subtitles {
  margin: 0;
  padding: 0;
  background: none;
  overflow: auto;
}

//original selectors
//#reserve .buttons li, #specials .buttons li
%list_items {
  background: none;
  padding: 0;
  margin: 0;
}

//original selectors
//#reserve .buttons li a, #specials .buttons li a
%list_item_links {
  @include main-font-semibold-i;
  color: $color_san_juan_approx;
  text-decoration: none;
  display: block;
  padding: 8px;
  margin: 0;
  margin-bottom: $space-m;
  background: $color_athens_gray_approx;
  font-size: 150%;
  text-align: center;
  @include main-font;
  line-height: 1.2;
}

//original selectors
//#reserve .buttons li a span, #specials .buttons li a span
%list_item_spans {
  @include main-font-i;
  font-size: 70%;
  display: block;
}

//original selectors
//#reserve form fieldset legend, #reserve form fieldset h3
%legend {
  color: $color_san_juan_approx;
  font-size: 140%;
  padding: $space-stack-m;
}

a {
  color: $color_fun_blue_approx;
  outline: none;
  &:visited {
    color: $color_rum_approx;
  }
  &:hover {
    color: $color_meteor_approx;
  }
}
img {
  border: 0;
}
ul {
  @extend %no-bullet;
}
li {
  @extend %no-bullet;
}
p {
  margin: 0 0 12px;
}
iframe {
  border: 0;
}
table {
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  td {
    padding: 0 8px 0 0;
    vertical-align: top;
    &:last-child {
      padding-right: 0;
    }
  }
  &.border td {
    border-top: 1px solid $color_fun_blue_approx;
  }
  &#confirm {
    &, tbody, .passengers {
      width: 100%;
    }
    tr {
      @extend .row;
      max-width: 100%;
      padding: $space-stack-xs;
      margin: 0;
      @include media-breakpoint-down(md) {
        padding: $space-xs 0;
        margin: 0;
        td {
          padding: 0;
        }
      }
      td {
        @extend .col-xs-12;
        @extend .col-lg-6;
        padding-left: 0;
      }
    }
  }
  .passengers {
    td {
      text-align: right;
    }
    .subtotal {
      width: 10%;
      color: $color_blue_bayoux_approx;
    }
    .taxable {
      width: 1%;
      color: $color_blue_bayoux_approx;
    }
    .total {
      font-size: 130%;
    }
  }
}

.fa:hover {
  color: $white;
}

#tour-unavailable {
  display: block;
  width: 100%;
  text-align: center;
  background-color: $color_athens_gray_approx;
  padding: $space-m;
  margin: $space-stack-m;
  h3 {
    font-family: Georgia, sans-serif;
    font-size: 22pt;
  }
  span {
    margin-top: $space-m;
    font-family: Helvetica, sans-serif;
    font-size: 18pt;
    font-weight: bold;
  }
}

#reserve {
  .main-content {
    h1,h2 {
      display: block;
      width: 100%;
      color: $master-blue;
      margin: $space-stack-m;
    }
    h2 {
      @include media-breakpoint-down(md) {
        font-size: 30px;
        line-height: 38px;
      }
    }
    @extend .container-fluid;
    section {
      @extend .row;
      div.col-xs-12.col-lg-9 {
        padding: 0;
      }
      p {
        @extend .col-xs-12;
        padding: 0;
      }
      .fb_share_link {
        padding: 0;
        font-size: 18px !important;
        padding-left: $space-m + $space-xs;
        margin-bottom: 24px;
        height: 25px;
        background-position: 0 $space-xs;
        @include media-breakpoint-down(lg) {
          background-position: 0 0;
        }
      }
      .reservation-col-l {
        padding: 0;
      }
      .reservation-col-r {
        @include media-breakpoint-down(md) {
          padding: 0;
        }
      }
      hr {
        width: 100%;
        margin: 16px 0;
        border: 1px solid $color_steel_blue_approx;
        border-width: 1px 0 0;
      }
      #datepop {
        cursor: pointer;
        vertical-align: bottom;
      }
      .buttons {
        @extend .col-xs-12;
        @extend .col-lg-8;
        padding: 0;
        li {
          @extend %list_items;
          a {
            @extend %list_item_links;
            span {
              @extend %list_item_spans;
            }
            &:hover {
              color: $white;
              background: $color_fun_blue_approx;
            }
          }
        }
      }
      .reservations-right {
        @extend .col-xs-12;
        @extend .col-lg-4;
        @include media-breakpoint-down(md) {
          padding: 0;
        }
        #concierge {
          padding: $space-m;
          width: 100%;
          font-size: 95%;
          background: $color_wheatfield_approx;
          margin-bottom: $space-m;
          h3 {
            color: $color_meteor_approx;
          }
        }
        #savebig {
          padding: $space-m;
          width: 100%;
          font-size: 95%;
          margin-bottom: $space-m;
          background: $color_athens_gray_approx;
          ul {
            padding-left: $space-m;
            padding-bottom: $space-m;
          }
          li {
            list-style-type: circle;
          }
          h3 {
            color: $color_meteor_approx;
          }
        }
      }
      form {
        @extend .col-xs-12;
        @extend .col-lg-9;
        background-color: $color_athens_gray_approx;
        border-top: 2px solid $color_fun_blue_approx;
        padding: $space-m;
        input[type="button"],
        input[type="submit"] {
          @extend .btn;
          @extend .btn-pill;
          @extend .btn-bg-blue;
          @extend .text-white;
          float: right;
        }

        input[type="text"],
        input[type="tel"],
        textarea {
          @extend .form-control;
        }

        input[type="checkbox"] {
          margin-right: $space-xs;
        }

        select {
          &#month,&#year{
            font-size: 16px !important;
            font-weight: normal !important;
          }
        }

        legend,h3 {
          @extend %legend;
        }

        label {
          margin: $space-stack-xs;
        }

        &#form1 {
          input[type="text"] {
            padding: $space-xs;
            min-width: 3em;
            height: 24px;
            width: 100%;
            &#promo_code {
              width: 100% !important;
            }
          }
          fieldset {
            width: 100%;
            border-width: 0;
            overflow: auto;
            margin: 0;
            .radioset {
              div {
                float: left;
                margin: 0 10px 6px 0;
              }
              label {
                float: none;
                width: auto;
                text-align: left;
                padding: 2px 0 0 5px;
              }
              input {
                float: none;
                width: auto;
              }
            }
            label {
              float: left;
              width: 110px;
              text-align: right;
              padding: 4px 10px 0;
              .note {
                display: block;
                text-align: right;
                margin: 0;
              }
            }
            .checkset {
              label {
                width: 200px;
                padding: 2px 0 0 5px;
                text-align: left;
              }
              input {
                width: auto;
                margin-left: 109px;
              }
            }
            input {
              float: left;
              width: 200px;
              &.disabled {
                font-size: 100%;
                font-weight: bold;
                @include main-font;
                text-align: right;
                color: $color_san_juan_approx !important;
              }
            }
            .entryset div {
              float: left;
              margin: 0 10px 6px 0;
            }
          }
        }
        &#paymentform {
          div {
            height: auto;
            @extend .form-group;
          }
          #zip {
            width: 2*$space-xl !important;
          }
          #x_card_code {
            width: $space-xl !important;
          }
          .checkset {
            display: flex;
            justify-content: space-between;
            input {
              margin-top: 5px;
              width: 25px;
              @include media-breakpoint-down(md) {
                margin-top: 3px;
              }
            }
            label {
              margin: 0;
              width: 100%;
            }
          }

          .stripe-input {
            padding: 10px;
            height: 41px;
            background-color: white;
            display: inline-block;
            width: 100%;
            border: 1px solid $pre-border-color;
            border-radius: $border-radius;
          }

          &.block-ui {
            position: relative;
            cursor: wait;
            
            &:after {
              content: '';
              position: absolute;
              width: 100%; height: 100%;
              top: 0px; left: 0px;
              background-color: rgba(255,255,255,.6);
            }
          }
        }
        &#submit_reservation {
          padding: $space-m;
          .button {
            display: flex;
            padding: 0;
            height: 32px;
            align-items: center;
            justify-content: flex-end;
            @include media-breakpoint-down(md) {
              flex-direction: column;
              height: 65px;
              justify-content: center;
              align-items: center;
              span {
                margin: $space-stack-s !important;
                strong {
                  display: none;
                }
              }
            }
            span {
              font-size: 100% !important;
              color: $master-red !important;
              margin-right: $space-m;
            }
          }
        }
        .required {
          font-weight: bold;
          color: $color_san_juan_approx;
          &:before {
            content: "*";
          }
        }
        .button {
          height: 40px;
          text-align: right;
        }
      }
      .column-r {
        display: none;
      }
      .addthis_toolbox {
        display: none;
      }
      #subnav #sn-giftcert a {
        background-position: -187px -24px;
      }
      #nav #n-reserve a {
        background-position: -231px -24px;
      }
      #tourselect #month form {
        width: 100%;
        padding: .4em 0;
        margin-top: $space-m;
        border-top: 0;
        background: $color_san_juan_approx;
      }
    }
  }
}


.smaller {
  font-size: 95%;
  color: $color_storm_dust_approx;
}

.note {
  @include main-font;
  font-size: 16px;
  color: $color_storm_dust_approx;
  line-height: 20px;
  margin-bottom: $space-s !important;
}
.l {
  text-align: left !important;
}
.r {
  text-align: right !important;
}
.error_message {
  @extend .col-xs-12;
  @extend .col-lg-9;
  padding: $space-m !important;
  margin: $space-s 0;
  border: 1px solid $color_old_rose_approx;
  background: $color_vanilla_ice_approx $url_13 8px no-repeat;
  clear: both;
  p:last-child {
    margin: 0;
  }
}
.payment-errors {
  color: $master-red;
}
#reservesteps {
  @extend .col-xs-12;
  @extend %subtitles;
  display: flex;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  padding-bottom: $space-xs;
  border-bottom: 1px solid $color_santas_gray_approx;
  li {
    @extend %subtitles;
    float: left;
    margin-right: $space-xs;
    @include media-breakpoint-down(md) {
      margin-right: 0;
    }
    padding: 4px 12px;
    background: $color_athens_gray_approx;
    color: $color_san_juan_approx;
    &.current {
      color: $white;
      background: $color_fun_blue_approx;
    }
    &.complete {
      color: $color_santas_gray_approx;
    }
  }
}
#tourselect {
  width: 100%;
  border: 1px solid $color_fun_blue_approx;
  margin-bottom: $space-m;
  form {
    width: 60%;
    background-color: $color_athens_gray_approx;
    border-top: 0;
  }
  th {
    color: $white;
    background: $color_fun_blue_approx;
    padding: 6px 0;
    width: 14%;
    text-align: center;
  }
  td {
    width: 14%;
    border: 1px solid $color_ghost_approx;
    height: 70px;
    background: $white;
    padding: 0;
    div {
      clear: both;
      text-align: center;
      padding: 2px 0;
      margin-bottom: 6px;
      &:hover {
        background: $color_athens_gray_approx;
      }
      &.full {
        color: $color_santas_gray_approx;
        background: $white;
        span {
          color: $color_santas_gray_approx;
          background: $white;
        }
      }
      span {
        display: block;
        font-size: 95%;
        color: $color_bermuda_gray_approx;
      }
    }
    a {
      display: block;
    }
    .date {
      float: right;
      background: $color_athens_gray_approx;
      color: $color_bermuda_gray_approx;
      border: 1px solid $color_ghost_approx;
      border-width: 0 0 1px 1px;
      padding: 1px;
      width: 24px;
      text-align: center;
      font-size: 95%;
    }
  }
  #month th {
    text-align: center;
    background: $color_athens_gray_approx;
    color: $color_san_juan_approx;
    font-size: 140%;
    padding: 0;
    padding-top: $space-m;
    a {
      text-decoration: none;
    }
  }
}
.groupdata {
  width: 100%;
  
  th {
    color: $color_blue_bayoux_approx;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid $color_loblolly_approx;
  }
  td {
    padding-bottom: $space-s;
    padding-top: $space-s;
    text-align: right;
    line-height: 24px;
    &.rate {
      color: $color_blue_bayoux_approx;
      white-space: nowrap;
    }
    &.total {
      color: $color_blue_bayoux_approx;
      font-weight: bold;
      width: 45px;
    }
    input {
    }
    .note {
      display: block;
      margin: 0;
      text-align: right;
    }
  }
}
