// general styling
.btn {
  border-radius: 0;
  @include main-font-light;
  transition: all 0.2s ease-in-out;
  &:hover {
    text-decoration: none !important;
  }
}
.btn-pill {
  border-radius: 20px;
  border: none;
  padding: $space-xs $space-l;
  @include main-font-semibold;
  &:hover {
    text-decoration: none !important;
  }
}

// button colors

.btn-bg-blue {
  background: $master-blue !important;
  &:hover {
    background-color: darken($master-blue, 10%) !important;
  }
}

.btn-bg-light-blue {
  background: $light-blue !important;
  &:hover {
    background-color: darken($light-blue, 10%) !important;
  }
}

.btn-bg-lighter-blue {
  background: $lighter-blue !important;
  &:hover {
    background-color: darken($lighter-blue, 10%) !important;
  }
}

.btn-bg-red {
  background: $master-red !important;
  &:hover {
    background-color: darken($master-red, 10%) !important;
  }
}

.btn-bg-orange {
  background: $master-orange !important;
  &:hover {
    background-color: darken($master-orange, 10%) !important;
  }
}

.btn-bg-gray {
  background: $master-gray !important;
  &:hover {
    background-color: darken($master-gray, 10%) !important;
  }
}

.btn-bg-yellow {
  background: $master-yellow !important;
  &:hover {
    background-color: darken($master-yellow, 10%) !important;
  }
}

.btn-bg-green {
  background: $master-green !important;
  &:hover {
    background-color: darken($master-green, 10%) !important;
  }
}

.btn-text-white {
  color: $white !important;
  &:hover, &:focus, &:active {
    color: $white;
  }
}

.btn-text-blue {
  color: $master-blue !important;
  &:hover, &:focus, &:active {
    color: $master-blue;
  }
}
