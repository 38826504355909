@import
"bootstrap/_variables.scss",
"bootstrap/mixins/_breakpoints.scss",
'_variables.scss',
'_font-face.scss';

@mixin main-font-semibold {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

@mixin main-font {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

@mixin main-font-light {
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
}

@mixin main-font-bold {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
}

@mixin cursive-font {
    font-family: 'IslandAdventuresScript', sans-serif;
    font-weight: 400;
}

@mixin main-font-semibold-i {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400 !important;
}

@mixin main-font-i {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300 !important;
}

@mixin main-font-light-i {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 200 !important;
}

@mixin main-font-bold-i {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 600 !important;
}

@mixin cursive-font-i {
    font-family: 'IslandAdventuresScript', sans-serif !important;
    font-weight: 400 !important;
}

/*********************
H1
*********************/

$desktop-h1-size: 43px;
$desktop-h1-line-height: 45px;
$laptop-h1-size: 43px;
$laptop-h1-line-height: 45px;
$tablet-h1-size: 43px;
$tablet-h1-line-height: 45px;
$mobile-h1-size: 43px;
$mobile-h1-line-height: 45px;

h1 {
    @include cursive-font;
    margin: 0;
    font-size: $mobile-h1-size;
    line-height: $mobile-h1-line-height;
    @include media-breakpoint-up(md) {
        font-size: $tablet-h1-size;
        line-height: $tablet-h1-line-height;
    }
    @include media-breakpoint-up(lg) {
        font-size: $laptop-h1-size;
        line-height: $laptop-h1-line-height;
    }
    @include media-breakpoint-up(xl) {
        font-size: $desktop-h1-size;
        line-height: $desktop-h1-line-height;
    }
    .title & {
        text-align: center;
    }
}

/*********************
H2
*********************/

$desktop-h2-size: 40px;
$desktop-h2-line-height: 42px;
$laptop-h2-size: 40px;
$laptop-h2-line-height: 42px;
$mobile-h2-size: 40px;
$mobile-h2-line-height: 42px;


h2 {
    @include main-font;
    margin: 0;
    font-size: $mobile-h2-size;
    line-height: $mobile-h2-line-height;
    @include media-breakpoint-up(lg) {
        font-size: $laptop-h2-size;
        line-height: $laptop-h2-line-height;
    }
    @include media-breakpoint-up(xl) {
        font-size: $desktop-h2-size;
        line-height: $desktop-h2-line-height;
    }
}

/*********************
H3
*********************/

$desktop-h3-size: 25px;
$desktop-h3-line-height: 27px;
$laptop-h3-size: 25px;
$laptop-h3-line-height: 27px;
$mobile-h3-size: 25px;
$mobile-h3-line-height: 27px;

h3 {
    @include main-font;
    //color: $white;
    font-size: $mobile-h3-size;
    line-height: $mobile-h3-line-height;
    @include media-breakpoint-up(lg) {
        font-size: $laptop-h3-size;
        line-height: $laptop-h3-line-height;
    }
    @include media-breakpoint-up(xl) {
        font-size: $desktop-h3-size;
        line-height: $desktop-h3-line-height;
    }
    .card & {
        @include cursive-font;
    }
}

/*********************
H4
*********************/

$desktop-h4-size: 37px;
$desktop-h4-line-height: 39px;
$laptop-h4-size: 37px;
$laptop-h4-line-height: 39px;
$mobile-h4-size: 37px;
$mobile-h4-line-height: 39px;

h4 {
    @include main-font-bold;
    color: $white;
    font-size: $mobile-h4-size;
    line-height: $mobile-h4-line-height;
    @include media-breakpoint-up(lg) {
        font-size: $laptop-h4-size;
        line-height: $laptop-h4-line-height;
    }
    @include media-breakpoint-up(xl) {
        font-size: $desktop-h4-size;
        line-height: $desktop-h4-line-height;
    }
}

/*********************
H5
*********************/

$h5-size: 20px;
$h5-line-height: 22px;

h5 {
  @include cursive-font;
  color: $master-blue;
  font-size: $h5-size;
  line-height: $h5-line-height;
  .card & {
      @include main-font-bold
  }
}

/*********************
p
*********************/

$desktop-p-size: 18px;
$desktop-p-line-height: 22px;
$laptop-p-size: 15px;
$laptop-p-line-height: 17px;
$mobile-p-size: 15px;
$mobile-p-line-height: 17px;

body, p {
    @include main-font;
    color: $text-base;
    font-size: $mobile-p-size;
    line-height: $mobile-p-line-height;
    @include media-breakpoint-up(lg) {
        font-size: $laptop-p-size;
        line-height: $laptop-p-line-height;
    }
    @include media-breakpoint-up(xl) {
        font-size: $desktop-p-size;
        line-height: $desktop-p-line-height;
    }
}
blockquote h3 {
    @include cursive-font;
    font-size: $mobile-h2-size;
    line-height: $mobile-h2-line-height;
    @include media-breakpoint-up(lg) {
        font-size: $laptop-h2-size;
        line-height: $laptop-h2-line-height;
    }
    @include media-breakpoint-up(xl) {
        font-size: $desktop-h2-size;
        line-height: $desktop-h2-line-height;
    }
}
nav#mobile {
    font-size: 20px;
    line-height: 24px;
}
/* margins */

h2, h3, h4, h5, h6, p {
  margin: $space-m 0 $space-m 0;
  @include media-breakpoint-up(md) {
    margin: 0 0 $space-m 0;
  }
}
