.carousel {
  .row {
    flex-wrap: wrap;
    .col-xs-12 {
      max-width: 100%;
    }
  }
}
@media screen and (min-width: 992px) {
  .carousel {
    .row {
      flex-wrap: nowrap;
      .col-lg-6 {
        max-width: 50%;
      }
      .col-lg-7 {
        max-width: 58.33333%
      }
      .col-lg-8 {
        max-width: 66.66667%
      }
    }
  }
}
.slick-slider {
  margin-bottom: 0 !important;
  .slick-slide {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .slick-dots {
    bottom: $space-s;
    li {
      margin: 0;
      button:before {
        color: $white;
        opacity: 0.5;
        font-size: 7px;
      }
      &.slick-active {
        button:before {
          color: $white;
          opacity: 1;
        }
      }
    }
  }
  .slick-prev, .slick-next {
    bottom: $space-s;
    top: auto;
    z-index: 50;
    margin-bottom: -10px;
    &:before {
      font-size: 15px;
    }
  }
  .slick-prev {
    left: $space-s;
  }
  .slick-next {
    right: $space-s;
  }
}

.slide {
  position: relative;
  img {
    width: 100%;
  }
}
.imageColumn {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  img {
    margin: $space-s 0;
  }
}
.carousel-with-text {
  position: relative;
  margin-bottom: $space-m;
  h2 {
    position: absolute;
    z-index: 2;
    bottom: $space-m;
    left: $space-m;
    @include media-breakpoint-down(sm) {
      font-size: 30px;
    }
  }
}
