.snippets section {
  &.default {
    h2 {
      @extend .text-shadow;
    }
    height: 100%;
    img {
      width: 100%;
    }
  }
  &.availability {
    form {
      padding: $space-stack-s;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
        .col-lg-6 & {
          flex-direction: column;
        }
      }
      select.form-control,.monthpicker {
        width: 60% !important;
        @include main-font;
      }
      input#month-picker,
      select#city-picker {
        color: $black;
      }
      select.form-control,
      .monthpicker_input {
        font-size: 18px;
        line-height: 18px;
        display: flex;
        align-items: center;
        padding: $space-inset-s !important;
        span.placeholder {
          padding-left: $space-xs;
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
      @include media-breakpoint-down(lg) {
        label {
          display: none;
        }
        select.form-control,.monthpicker {
          width: 80% !important;
        }
        .form-group.monthpicker-wrapper .monthpicker_input {
          width: 100% !important;
        }
      }
      .form-group {
        margin-bottom: $space-s;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        a.btn.btn-pill {
          padding: $space-stack-xs;
        }
        @include media-breakpoint-up(lg) {
          width: 33%;
          margin: 0;
          .col-lg-6 & {
            width: 100%;
            margin-bottom: $space-s;
          }
        }
        label {
          width: 20%;
          margin-right: 5%;
        }
        input,select,a {
          height: 30px;
          width: 60%;
        }
      }
    }
  }
  &.buttons {
    .row {
      padding: 0;
      list-style: none;
      justify-content: center;
      div[class*='col-'] {
        margin-bottom: $space-m;
        .btn {
          display: block;
          height: 100%;
          width: 100%;
          white-space: normal;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span {
            margin: auto;
          }
          @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: center;
            // justify-content: space-between;
          }
          &:hover, &:active, &:focus {
            color: $white;
          }
        }
        .btn-rounded {
          border-radius: 20px;
        }
      }
    }
  }
  &.weather {
    margin-top: $space-m;
    .title-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: $space-m;
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        margin: $space-stack-m;
        .btn-pill {
          margin-top: $space-m;
          text-align: center;
          font-size: 18px;
        }
      }
    }
    .aw-widget-current {
      margin-bottom: $space-m;
    }
  }
  &.coupon {
    @include media-breakpoint-up(md) {
      margin-top: $space-m;
    }
    margin-bottom: $space-m;
    .coupon-content {
      display: flex;
      .supplemental-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        border-right: 5px solid $master-red;
        margin-right: $space-m;
        padding-right: $space-m;
      }
      .main-content {
        flex: 5;
      }
    }
  }
  &.menu {
    margin: $space-stack-l;
    .full-width {
      padding-top: $space-l;
      p {
        margin: 0;
      }
    }
    .menu-section {
      margin: $space-stack-l;
      h3 {
        border-bottom: 5px solid $master-blue;
      }
      .item-row {
        position: relative;
        @include main-font;
        margin: $space-stack-m;
        display: flex;
        justify-content: space-between;
        background-image: linear-gradient(to right, $master-blue 35%, transparent 0);
        background-size: 6px 2px;
        background-repeat: repeat-x;
        background-position: bottom;
        @include media-breakpoint-up(md) {
          background-position: bottom 3px right 0;
        }
        .item,.price {
          font-size: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          background-color: $bg-color;
        }
        .item {
          padding-right: $space-s;
        }
        .price {
          padding-left: $space-s;
        }
      }
    }
  }
  &.map {
    margin: $space-stack-l;
    .inner {
      position: relative;
      width: 100%;
      img {
        width: 100%;
      }
      .map-text {
        @include media-breakpoint-up(lg) {
          padding-left: 24px;
          padding-top: 24px;
          position: absolute;
          left: 0;
          top: 0;
          width: 24%;
          height: 95%;
        }
        h2 {
          font-size: 25px;
          line-height: 27px;
          @include media-breakpoint-only(xl) {
            font-size: 35px;
            line-height: 37px;
          }
        }
        p {
          @include main-font;
        }
      }
    }
  }
}
